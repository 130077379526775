<script setup lang="ts">
  import { isVNode } from 'vue';
  import { useToast } from './use-toast';
  import {
    Toast,
    ToastClose,
    ToastDescription,
    ToastProvider,
    ToastTitle,
    ToastViewport,
  } from '.';

  const { toasts } = useToast();

  const iconStyle = {
    error: 'fa-solid fa-xmark border border-red-600 text-red-600',
    warning: 'fa-solid fa-exclamation border border-yellow-600 text-yellow-600',
    success: 'fa-solid fa-check border border-green-600 text-green-600',
    default: 'fa-solid fa-info border border-gray-600 text-gray-600',
    info: 'fa-solid fa-info border border-blue-600 text-blue-600',
  };
</script>

<template>
  <ToastProvider>
    <Toast
      v-for="(toast, index) in toasts"
      :key="toast.id"
      v-bind="toast"
      :style="{
        right: `calc(0.5em * ${index - toasts.length} + 2em)`,
        bottom: `calc(0.5em * ${index - toasts.length} + 2em)`,
      }"
      class="absolute flex h-28 w-full">
      <div class="flex w-full flex-row items-center gap-4">
        <i
          v-if="toast.variant"
          :class="iconStyle[toast.variant]"
          class="aspect-square h-8 rounded-full p-2"></i>
        <div>
          <ToastTitle v-if="toast.title">
            {{ toast.title }}
          </ToastTitle>
          <template v-if="toast.description">
            <ToastDescription v-if="isVNode(toast.description)">
              <component :is="toast.description" />
            </ToastDescription>
            <ToastDescription v-else>
              {{ toast.description }}
            </ToastDescription>
          </template>
          <ToastClose />
        </div>
      </div>
      <component :is="toast.action" />
    </Toast>
    <ToastViewport />
  </ToastProvider>
</template>
