import type { NotificationInterface } from '@/interfaces/components/notification/notification.interface';
import { defineStore } from 'pinia';
import { useToast } from '@/components/ui/toast/use-toast';
import { h, ref } from 'vue';
import { ToastAction } from '@/components/ui/toast';

/**
 * Store responsável pelo gerenciamento de notificações do App
 */
export const useNotificationStore = defineStore(
  'AtenaNotificationStore',
  () => {
    const { toast } = useToast();

    // Estado para armazenar a última notificação
    const notification = ref<NotificationInterface | null>(null);

    /**
     * Adiciona uma notificação à fila de notificações do App
     * @param newNotification
     */
    const addNotification = (newNotification: NotificationInterface) => {
      // Atualiza o estado da notificação
      notification.value = newNotification;
      notification.value.showMessage = false;
      if (newNotification.id === 'USER_POLICIES_NOT_ACCEPTED_ERROR') {
        notification.value.title = 'Termos de uso e privacidade';
        notification.value.message =
          'É necessário aceitar os termos de uso e a política de privacidade para utilizar o sistema.';
        notification.value.showMessage = true;
      } else {
        toast({
          duration: newNotification.duration ?? 5000,
          title: newNotification.title,
          description: newNotification.message,
          variant: newNotification.type,
          action: newNotification.action
            ? h(
                ToastAction,
                {
                  altText: newNotification.action.label || 'Ação',
                  onClick: newNotification.action.callback,
                },
                {
                  default: () => newNotification.action?.label ?? 'Ação',
                },
              )
            : undefined,
        });
      }
    };
    const updateNotification = (newNotification: NotificationInterface) => {
      notification.value = newNotification;
    };
    return { addNotification, updateNotification, notification };
  },
  {
    persist: {
      key: 'AtenaNotification',
      storage: localStorage,
    },
  },
);
