import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router/index';
import { createHead } from '@unhead/vue';
import '@/settings/color.css';
import '@/assets/css/index.scss';

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fas, far, fab);
dom.watch();

// Configurações do projeto
import image from '@/settings/images';
import systemConstants from '@/settings/systemConstants';
import parameterizedRoute from '@/settings/parameterizedRoute';

// Componentes globais e bibliotecas
import 'dropzone-vue/dist/dropzone-vue.common.css';
import BarChart from '@/components/charts/BarChart.component.vue';
import DropZone from 'dropzone-vue';
import EmptyList from '@/components/base/EmptyList.component.vue';
import IsLoading from '@/components/base/loadings/Index.component.vue';
import LineChart from '@/components/charts/LineChart.component.vue';
import MainPageWrapper from '@/components/base/MainPageWrapper.component.vue';
import NotificationBase from '@/components/base/NotificationBase.component.vue';
import PageTitle from '@/components/base/PageTitle.component.vue';
import Pagination from '@/components/base/Pagination.component.vue';
import SelectWithSearch from '@/components/base/selects/SelectWithSearch.component.vue';
import UploadBase from '@/components/base/UploadBase.component.vue';
import VueScrollTo from 'vue-scrollto';
import VueTheMask from 'vue-the-mask';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { createPinia } from 'pinia';
import { Separator } from '@/components/ui/separator';
import { Label } from '@/components/ui/label/index';

import {
  Card,
  CardTitle,
  CardFooter,
  CardHeader,
  CardContent,
  CardDescription,
} from '@/components/ui/card';

import {
  FormItem,
  FormLabel,
  FormField,
  FormControl,
  FormMessage,
} from '@/components/ui/form';

import {
  SelectItem,
  SelectValue,
  SelectGroup,
  SelectTrigger,
  SelectContent,
  Select as SelectBase,
} from '@/components/ui/select';

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

import i18n from '@/settings/i18n/index';
import { useNotificationStore } from '@/stores/notification.store';
import { useSubsidiaryStore } from '@/stores/subsidiary.store';
import { useUserStore } from '@/stores/user.store';
import { vMaska } from 'maska/vue';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import { useFeatureFlagsStore } from '@/stores/featureFlag.store';
import { usePipelineStore } from '@/stores/pipeline.store';

// Componentes base e bibliotecas
app.use(i18n);
app.use(pinia);
app.use(VueScrollTo);
app.use(autoAnimatePlugin);
app.use(DropZone as never); // Mantendo asserção de tipo caso não haja tipagem
app.use(VueTheMask as never);

app.directive('maska', vMaska);

// Registro de componentes globais
app.component('Card', Card);
app.component('InputBase', Input);
app.component('LabelBase', Label);
app.component('ButtonBase', Button);
app.component('BarChart', BarChart);
app.component('FormItem', FormItem);
app.component('CardTitle', CardTitle);
app.component('EmptyList', EmptyList);
app.component('FormField', FormField);
app.component('FormLabel', FormLabel);
app.component('IsLoading', IsLoading);
app.component('LineChart', LineChart);
app.component('PageTitle', PageTitle);
app.component('Separator', Separator);
app.component('CardFooter', CardFooter);
app.component('CardHeader', CardHeader);
app.component('Pagination', Pagination);
app.component('SelectBase', SelectBase);
app.component('SelectItem', SelectItem);
app.component('UploadBase', UploadBase);
app.component('CardContent', CardContent);
app.component('FormControl', FormControl);
app.component('FormMessage', FormMessage);
app.component('SelectGroup', SelectGroup);
app.component('SelectValue', SelectValue);
app.component('SelectContent', SelectContent);
app.component('SelectTrigger', SelectTrigger);
app.component('CardDescription', CardDescription);
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('MainPageWrapper', MainPageWrapper);
app.component('NotificationBase', NotificationBase);
app.component('SelectWithSearch', SelectWithSearch);

// Configurações globais
app.config.globalProperties.$userStore = useUserStore();
app.config.globalProperties.$systemConstants = systemConstants;
app.config.globalProperties.$pipelineStore = usePipelineStore();
app.config.globalProperties.$featureStore = useFeatureFlagsStore();
app.config.globalProperties.$subsidiaryStore = useSubsidiaryStore();
app.config.globalProperties.$notificationStore = useNotificationStore();

// Mixin global
app.mixin({
  created() {
    this.$options.imageConfig = image;
    this.$options.systemConstants = systemConstants;
    this.$options.parameterizedRoute = parameterizedRoute;
    this.$options.userStore = app.config.globalProperties.$userStore;
  },
});

// Filtros globais
app.config.globalProperties.$filters = {
  annualFeeConverter(fee: number): number {
    return (1 + fee) ** 12 - 1;
  },
  monthlyFeeConverter(fee: number): number {
    return (1 + fee) ** (1 / 12) - 1;
  },
};

// Inicialização
app.use(router);
app.use(createHead());

app.mount('#app');

