/**
 * Chave e valor para os tipos de semáforo
 */
export enum StageSemaphoreFlagEnum {
    GREEN = 1,
    YELLOW = 2,
    RED = 3,
}

/**
 * Tipo de dados para os flags de semáforo de estágio
 */
export type StageSemaphoreFlagType =
  (typeof StageSemaphoreFlagEnum)[keyof typeof StageSemaphoreFlagEnum];

